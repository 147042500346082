<template>
  <router-link to="/index" class="logo-wrapper">
    <img v-if="type=='yf'" src="../../../assets/logo2.png" width="30" height="30" />
    <img v-else src="../../../assets/logo.png" width="30" height="30" />
    <div class="logo-title verticalMiddle">{{title}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'logo',
  data() {
    return {
      title:process.env.VUE_APP_BASE_NAME,
      type:process.env.VUE_APP_BASE_TYPE
    };
  }
};
</script>
<style scoped></style>
