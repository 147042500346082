import router from './router';
import { getCache } from '@/utils/session';
import getPageTitle from '@/utils/getPageTitle';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { message } from 'ant-design-vue';
import store from './store';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

let whiteList = ['/login', '/register'];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  store.commit('user/SET_LOADDING', true);
  if(to.path!='/station/salarySettlement' && to.path!='/station/salarySettlement/hide-salarySettleDetail'){
    store.commit("other/SET_MODE", '');
    store.commit("other/SET_WAGEMONTH", '');
    store.commit('other/SET_TIME', ''); 
    store.commit('other/SET_SEND_TIME', ''); 
    store.commit('other/SET_ALREADY_TIME', '');
  }
  document.title = getPageTitle(to.meta.title);
  const isLogin = getCache('TOKEN');
  // if (to.path == '/login') {
  if (whiteList.includes(to.path)) {
    next();
  } else {
    if (!isLogin) {
      next('/login');
    } else {
      const route = store.state.permission.routes;
      if (route.length > 0) {
        next();
      } else {
        // const userInfo = store.state.user.accountInfo;
        try {
          let accountRoute = store.getters.routes;
          if (!accountRoute || accountRoute.length <= 0) {
            accountRoute = await store.dispatch('permission/getRoute');
            router.addRoutes(accountRoute);

            if (from.path == '/login') {
              next(accountRoute[0].children[0].path);
            } else {
              next({ ...to, replace: true });
            }
          }
        } catch (error) {
          console.log(error);
          message.error('获取用户信息失败');
          store.commit('user/SET_LOADDING', false);
          next('/login');
        }
      }
    }
  }
});

router.afterEach(() => {
  NProgress.done();
  store.commit('user/SET_LOADDING', false);
});
