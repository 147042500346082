// import { getCache, setCache, removeCache } from '@/utils/session';
// import { login } from '@/api/user';
// import { resetRouter } from '@/router';
// import store from '../index';

const state = {
  registerTime: '',
  sendTime: '',
  alreadyTime: '',
  shipmentTime:"",
  wageMonth:"",
  mode:"",
  finishTime:""
};

const mutations = {
  SET_WAGEMONTH(state, wageMonth) {
    state.wageMonth = wageMonth;
    // localStorage.setItem("SET_WAGEMONTH",wageMonth);
  },
  SET_FINISHTIME(state,finishTime){
    state.finishTime=finishTime;
  },
  SET_MODE(state, mode) {
    // console.log("modestate",mode);
    state.mode = mode;
    // localStorage.setItem("SET_MODE",mode);
  },
  SET_TIME(state, registerTime) {
    state.registerTime = registerTime;
  },
  SET_SEND_TIME(state, sendTime) {
    state.sendTime = sendTime;
  },
  SET_ALREADY_TIME(state, alreadyTime) {
    state.alreadyTime = alreadyTime;
  },
  SET_SHIPMENT_TIME(state, shipmentTime) {
    state.shipmentTime = shipmentTime;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
